import React from "react"
import "../components/layout.css"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Footer from "../components/Navigation/Footer"
import Header from "../components/Navigation/Header"
import Seo from "../components/Seo"

import {
  BlogGrid,
  Container,
  BlogHeader,
  BlogImgCont,
  ImgCont,
  ImgDescript,
  ImgWrap,
  TitleWrap,
  Title,
  HeadingTwo,
  InfoComponent,
  ArticleStyle,
  ArticleOL,
  ArticleUL,
  DatePub,
  Content,
  RichLink,
  QuoteBlock,
} from "./poststyles"

import { StyledFrame2, LinearTitle } from "../styles/elements.js"

export const data = graphql`
  query blogQuery($slug: String) {
    contentfulBlog(slug: { eq: $slug }) {
      slug
      title
      id
      datePublished(formatString: "MMMM Do YYYY")
      image {
        gatsbyImageData
      }
      richTest {
        raw
        references {
          # ... on ContentfulTag {
          #   __typename
          #   contentful_id
          #   id
          #   title
          # }
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            fixed(width: 1600) {
              width
              height
              src
              srcSet
            }
          }

          # ... on ContentfulBlog {
          #   __typename
          #   contentful_id
          #   title
          #   metaDescription {
          #     metaDescription
          #   }
          # }
          # ... on ContentfulPage {
          #   __typename
          #   contentful_id
          #   title
          # }
        }
      }
    }
  }
`
// TODO: why are you passing in node, and siteTitle below
const BlogLayout = ({ children, siteTitle, data }) => {
  // render assets for embedded asset block
  const assets = new Map(
    data.contentfulBlog.richTest?.references.map(ref => [
      ref.contentful_id,
      ref,
    ])
  )

  const { richTest } = data.contentfulBlog
  const image = getImage(data.contentfulBlog.image)
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        if (node.data.target.sys.contentType.sys.id === "videoEmbed") {
          return (
            <iframe
              src={node.data.target.fields.embedUrl}
              height="100%"
              width="100%"
              frameBorder="0"
              scrolling="no"
              title={node.data.target.fields.title}
              allowFullScreen={true}
            />
          )
        }
      },

      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const url = assets.get(node.data.target.sys.id).fixed.src
        const alt = assets.get(node.data.target.sys.id).title

        return (
          <>
            <BlogImgCont>
              <img alt={alt} src={url} />
            </BlogImgCont>
            <ImgDescript>{alt}</ImgDescript>
          </>
        )
      },

      [BLOCKS.HEADING_1]: (node, children) => (
        <LinearTitle>{children}</LinearTitle>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <HeadingTwo>{children}</HeadingTwo>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 style={{ color: "white" }}>{children}</h3>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <QuoteBlock>
          <p>{children}</p>
        </QuoteBlock>
      ),
      [BLOCKS.OL_LIST]: (node, children) => <ArticleOL>{children}</ArticleOL>,
      [BLOCKS.UL_LIST]: (node, children) => <ArticleUL>{children}</ArticleUL>,

      [INLINES.HYPERLINK]: node => {
        if (node.data.uri.includes("player.vimeo.com/video")) {
          return (
            <StyledFrame2
              title="Unique Title 001"
              src={node.data.uri}
              frameBorder="0"
              allowFullScreen
            ></StyledFrame2>
          )
        } else if (node.data.uri.includes("youtube.com/embed")) {
          return (
            <StyledFrame2
              title={node.data.uri}
              src={node.data.uri}
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            ></StyledFrame2>
          )
        } else {
          return (
            <RichLink href={node.data.uri} target="_blank">
              {/* , should be??? was:  {node.content[0].value} */}
            </RichLink>
          )
        }
      },

      /*       [INLINES.HYPERLINK]: (node, children) => <RichLink>{children}</RichLink>,
       */ [BLOCKS.PARAGRAPH]: (node, children) => (
        <p style={{ color: "#ddd" }}>{children}</p>
      ),
    },
  }

  return (
    <>
      <Header />
      <Seo
        title={data.contentfulBlog.title}
        description={`CompoundYourself: ` + data.contentfulBlog.title}
      />
      <Container>
        <BlogGrid>
          <BlogHeader>
            <ImgCont>
              <ImgWrap>
                {data.contentfulBlog.image && (
                  <GatsbyImage image={image} alt={""} />
                )}
              </ImgWrap>
              <TitleWrap>
                <Title>{data.contentfulBlog.title}</Title>
                <DatePub>{data.contentfulBlog.datePublished}</DatePub>
              </TitleWrap>
            </ImgCont>
          </BlogHeader>
          <InfoComponent></InfoComponent>

          <ArticleStyle>
            <Content>{richTest && renderRichText(richTest, options)}</Content>
          </ArticleStyle>
        </BlogGrid>

        <main>{children}</main>
        <Footer />
      </Container>
    </>
  )
}

export default BlogLayout
